// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---plugins-gatsby-source-dpt-corp-www-src-pages-howitworks-js": () => import("./../../../plugins/gatsby-source-dpt-corp-www/src/pages/howitworks.js" /* webpackChunkName: "component---plugins-gatsby-source-dpt-corp-www-src-pages-howitworks-js" */),
  "component---plugins-gatsby-source-dpt-corp-www-src-pages-solutions-business-js": () => import("./../../../plugins/gatsby-source-dpt-corp-www/src/pages/solutions/business.js" /* webpackChunkName: "component---plugins-gatsby-source-dpt-corp-www-src-pages-solutions-business-js" */),
  "component---plugins-gatsby-source-dpt-corp-www-src-pages-solutions-partners-js": () => import("./../../../plugins/gatsby-source-dpt-corp-www/src/pages/solutions/partners.js" /* webpackChunkName: "component---plugins-gatsby-source-dpt-corp-www-src-pages-solutions-partners-js" */),
  "component---plugins-gatsby-source-dpt-corp-www-src-pages-solutions-people-js": () => import("./../../../plugins/gatsby-source-dpt-corp-www/src/pages/solutions/people.js" /* webpackChunkName: "component---plugins-gatsby-source-dpt-corp-www-src-pages-solutions-people-js" */),
  "component---plugins-gatsby-source-dpt-corp-www-src-pages-solutions-providers-js": () => import("./../../../plugins/gatsby-source-dpt-corp-www/src/pages/solutions/providers.js" /* webpackChunkName: "component---plugins-gatsby-source-dpt-corp-www-src-pages-solutions-providers-js" */),
  "component---plugins-gatsby-source-dpt-corp-www-src-pages-solutions-startups-js": () => import("./../../../plugins/gatsby-source-dpt-corp-www/src/pages/solutions/startups.js" /* webpackChunkName: "component---plugins-gatsby-source-dpt-corp-www-src-pages-solutions-startups-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lets-talk-index-js": () => import("./../../../src/pages/lets-talk/index.js" /* webpackChunkName: "component---src-pages-lets-talk-index-js" */),
  "component---src-pages-lets-talk-partners-js": () => import("./../../../src/pages/lets-talk/partners.js" /* webpackChunkName: "component---src-pages-lets-talk-partners-js" */),
  "component---src-pages-lets-talk-providers-js": () => import("./../../../src/pages/lets-talk/providers.js" /* webpackChunkName: "component---src-pages-lets-talk-providers-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-what-js": () => import("./../../../src/pages/what.js" /* webpackChunkName: "component---src-pages-what-js" */),
  "component---src-pages-why-js": () => import("./../../../src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */)
}

