/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import './src/sass/app.scss'
import 'jquery/dist/jquery.min.js'
// import 'popper.js/dist/popper.min'
import 'bootstrap/dist/js/bootstrap.min.js'




// import "../../static/styles/main.scss"
// import "../../static/theme/css/theme-blue.css"
// import "../../static/theme/css/icons.min.css"

// import './static/css/base.css';
import "./static/styles/main.scss"
import "./static/theme/css/theme-blue.css"
import "./static/theme/css/icons.min.css"

import './src/pages/custom.css'; // applies globally

import React from "react"
import ThemeContext, { ThemeProvider } from "./src/utils/theme"
// import "./src/styles/global.scss"

/** This component wraps around the entire root div where we render our content.
 *  So, this piece of code is applying the theme-light/dark class on the top level
 */
export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <ThemeContext.Consumer>
      {({ toString }) => <div className={`theme2-${toString()}`}>{element}</div>}
    </ThemeContext.Consumer>
  </ThemeProvider>
)






import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// import App from "./App";

if (process.env.GATSBY_ACTIVE_ENV=="demo" || 
process.env.REACT_APP_BUILD_ENV=="demo" || 
process.env.NODE_ENV=="development" ) {

    Sentry.init({
    dsn: "https://197b1f75f39a4d5899f728712ad134de@o851843.ingest.sentry.io/5818676",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    });

}